import Vue from 'vue';
import App from './App.vue';
import router from './router'
import VueMeta from 'vue-meta';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.prototype.$http = axios;

Vue.use(VueMeta);
Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon)


new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

<template>
    <div class="main-navigation--new d-flex bg-white px-3 py-md-0 py-3 align-items-center justify-content-between shadow-sm">
        <div class="d-flex align-items-center">
            <div class="mr-4">
                <router-link to="/">
                    <img src="https://assets.scola.id/newwebsite/images/Scola_Logo_Lockup_Blue_NEW.svg" alt="Scola Logo" width="100">
                </router-link>
            </div>
            <div class="hide-767">
                <ul class="navbar-nav horizontal nav-black navbar-nav-new">
                    <li class="nav-item">
                        <router-link class="nav-link" :active-class="activeClass == 'about' ? 'active-link':''" to="/about">Tentang Kami</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :active-class="activeClass == 'product' ? 'active-link':''" to="/product">Produk</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :active-class="activeClass == 'program' ? 'active-link':''" to="/program">Program</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hide-767">
            <div class="d-flex align-items-center justify-content-end">
                <ul class="navbar-nav horizontal nav-black navbar-nav-new">
                    <li class="nav-item">
                        <a class="nav-link" :active-class="activeClass == 'blog' ? 'active-link':''" href="https://artikel.scola.id/" target="_blank">Artikel</a>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :active-class="activeClass == 'contact' ? 'active-link':''" to="/contact">Kontak</router-link>
                    </li>
                    <li class="nav-item nav-item-action">
                        <a class="nav-link" :active-class="activeClass == 'register' ? 'active-link':''" target="_blank" href="https://form.jotform.com/222552676470459">
                            <div class="d-block px-2">Daftar Sekarang</div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="show-767">
            <button id="navToggle" type="button" class="nav-toggle nav-toggle-relative" @click="openMenu" role="button" aria-label="navToggle">
                <span></span>
            </button>
        </div>
    </div>
</template>

<script>
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faBolt, faArrowRight } from '@fortawesome/free-solid-svg-icons';

    library.add(faBolt, faArrowRight)

    export default {
        props : {
            activeClass : {
                type : String,
                default : 'home'
            }
        },
        mounted() {
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            },
            openMenu() {
                var navToggle = document.getElementById("navToggle");
                var navMobile = document.getElementById("navMobile");

                navToggle.classList.toggle("active");
                navMobile.classList.toggle("active");
            }
        }
    }
</script>
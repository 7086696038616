<template>
    <div class="d-block">
        <header-components></header-components>
        <header-components-mobile></header-components-mobile>
        <!-- Hero Header -->
        <div class="section-xl bg-image bg-image-clear" style="background-image:url('/images/bg-scola-galaxy-2.webp');background-position:top;">
            <div class="container-fluid container-fluid-gap">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="d-block text-center text-white">
                            <h1 class="text-xxxlg font-600">Satu aplikasi untuk semua solusi pendidikan</h1>
                            <div class="text-md">Mudah digunakan, andal, dan aman. Tidak heran aplikasi Scola menjadi pilihan mitra untuk mewujudkan sekolah terbaik Anda.</div>
                        </div>
                        <div class="d-flex justify-content-center pt-4">
                            <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary rounded-pill mx-2">Daftar Sekarang</a>
                            <a href="https://form.jotform.com/222553276834460" class="btn btn-white rounded-pill mx-2">Coba Gratis</a>
                        </div>
                    </div>
                </div>
                <div class="d-block pt-5">
                    <div class="hide-767">
                        <img src="/images/group-product-element.webp" class="d-block mx-auto" style="width:76%!important;" alt="home-image" >
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-6 mb-md-0 mb-4">
                            <div class="card card-glass shadow-sm h-100 mb-0 lift">
                                <div class="card-body text-white h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-3" style="margin-left:-8px;">
                                                <img alt="home-image" src="/images/icon-product-1.webp" width="80">
                                            </div>
                                            <div class="h2 font-600 mb-2">SMART CLASS</div>
                                            <div class="d-block">Solusi terbaik pembelajaran digital di sekolah.</div>
                                        </div>
                                        <div class="d-block pt-5">
                                            <router-link to="/product-lms" class="btn btn-link p-0 text-white text-underline">Pelajari selengkapnya</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-6 mb-md-0 mb-4">
                            <div class="card card-glass shadow-sm h-100 mb-0 lift">
                                <div class="card-body text-white h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-3" style="margin-left:-8px;">
                                                <img alt="home-image" src="/images/icon-product-2.webp" width="80">
                                            </div>
                                            <div class="h2 font-600 mb-2">SCHOOL PRO</div>
                                            <div class="d-block">Kelola data sekolah dengan teratur & terpusat.</div>
                                        </div>
                                        <div class="d-block pt-5">
                                            <router-link to="/product-si" class="btn btn-link p-0 text-white text-underline">Pelajari selengkapnya</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-6 mb-md-0 mb-4">
                            <div class="card card-glass shadow-sm h-100 mb-0 lift">
                                <div class="card-body text-white h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-3" style="margin-left:-8px;">
                                                <img alt="home-image" src="/images/icon-product-3.webp" width="80">
                                            </div>
                                            <div class="h2 font-600 mb-2">INSTITUTION</div>
                                            <div class="d-block">Lihat performa sekolah Anda dengan spesifik.</div>
                                        </div>
                                        <div class="d-block pt-5">
                                            <router-link to="/product-sil" class="btn btn-link p-0 text-white text-underline">Pelajari selengkapnya</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-6 mb-md-0 mb-4">
                            <div class="card card-glass shadow-sm h-100 mb-0 lift">
                                <div class="card-body text-white h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-3" style="margin-left:-8px;">
                                                <img alt="home-image" src="/images/icon-product-4.webp" width="80">
                                            </div>
                                            <div class="h2 font-600 mb-2">CONTENT</div>
                                            <div class="d-block">Integrasi konten untuk dukung pembelajaran digital.</div>
                                        </div>
                                        <!-- <div class="d-block pt-5">
                                            <a href="#" class="btn btn-link p-0 text-white text-underline">Pelajari selengkapnya</a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-block px-3 py-4 bg-primary-dark text-white" style="position:relative;">
            <img alt="home-image" src="/images/owly-hi.webp" class="owly-hi">
            <div class="container-fluid container-fluid-gap">
                <div class="hide-767">
                    <div class="text-center text-uppercase font-600">Solusi Scola dirancang untuk semua jenis kebutuhan sekolah</div>
                </div>
                <div class="show-767">
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-8">
                            <div class="text-left text-uppercase font-600">Solusi Scola dirancang untuk semua jenis kebutuhan sekolah</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-none section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="row justify-content-center">
                    <div class="col-md-10 text-center mb-4">
                        <div class="d-block text-sm font-600 text-primary">#ekosistempendidikandigital adawd</div>
                        <h1 class="text-mega font-600">Satu Aplikasi untuk semua <span class="text-primary">solusi pendidikan.</span></h1>
                        <p class="text-lg">Wujudkan Sekolah Digital Anda Bersama Scola, aplikasi ekosistem pendidikan digital dengan solusi terlengkap dan terbaik!</p>
                        <div class="d-flex align-items-center justify-content-center pt-3">
                            <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg mr-2 rounded-pill">Daftar Sekarang</a>
                            <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill font-italic">Request Free Trial</a>
                        </div>
                    </div>
                    <div class="col-md-12 my-4">
                        <div class="product-logo-group border rounded-lg pb-5 pt-5 justify-content-around">
                            <div class="product-logo-title text-muted">Solusi untuk Sekolah Digital</div>
                            
                            <div class="mx-2 text-center product-logo-item mb-md-0 mb-4">
                                <img alt="home-image" src="/images/product-logo-icon/scola-smart-class-icon.svg" width="80">
                                <div class="d-block pt-2">
                                    <div class="d-block text-center font-600">Smart Class</div>
                                    <div class="d-block text-xs">Pembelajaran</div>
                                </div>
                            </div>
                            <div class="ml-lg-0 ml-5 hide-767">
                                <font-awesome-icon icon="fa-solid fa-arrows-left-right" class="fa-lg text-muted hide-767"/>
                                <font-awesome-icon icon="fa-solid fa-plus" class="fa-lg text-muted show-767"/>
                            </div>
                            <div class="mx-2 text-center product-logo-item mb-md-0 mb-4">
                                <img alt="home-image" src="/images/product-logo-icon/scola-school-pro-icon.svg" width="80">
                                <div class="d-block pt-2">
                                    <div class="d-block text-center font-600">School Pro</div>
                                    <div class="d-block text-xs">Sistem Informasi</div>
                                </div>
                            </div>
                            <div class="ml-lg-0 w-100-767">
                                <div class="d-flex align-items-center justify-content-center">
                                    <font-awesome-icon icon="fa-solid fa-arrows-left-right" class="fa-lg text-muted hide-767"/>
                                    <font-awesome-icon icon="fa-solid fa-plus" class="fa-lg text-muted show-767"/>
                                </div>
                            </div>
                            <div class="mx-2 text-center product-logo-item">
                                <img alt="home-image" src="/images/product-logo-icon/scola-institution-icon.svg" width="80">
                                <div class="d-block pt-2">
                                    <div class="d-block text-center font-600">Institution</div>
                                    <div class="d-block text-xs">SI Yayasan</div>
                                </div>
                            </div>
                            <div class="ml-lg-0 ml-5 hide-767">
                                <font-awesome-icon icon="fa-solid fa-arrows-left-right" class="fa-lg text-muted hide-767"/>
                                <font-awesome-icon icon="fa-solid fa-plus" class="fa-lg text-muted show-767"/>
                            </div>
                            <div class="mx-2 text-center product-logo-item">
                                <img alt="home-image" src="/images/product-logo-icon/scola-content-icon.svg" width="80">
                                <div class="d-block pt-2">
                                    <div class="d-block text-center font-600">Content</div>
                                    <div class="d-block text-xs">Media Pembelajaran</div>
                                </div>
                            </div>
                                
                        
                            <!-- <div>
                                <font-awesome-icon icon="fa-solid fa-arrows-left-right" class="fa-lg text-muted hide-767"/>
                                <font-awesome-icon icon="fa-solid fa-plus" class="fa-lg text-muted show-767"/>
                            </div>
                            <div class="mx-2 text-center product-logo-item">
                                <img alt="home-image" src="/images/product-logo-icon/scola-apps-icon.svg" width="80">
                                <div class="d-block pt-2">
                                    <div class="d-block text-center font-600">Apps</div>
                                    <div class="d-block text-xs">Aplikasi Pendukung</div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-10">
                        <img alt="home-image" src="/images/assets-header-image.webp" class="w-100">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <!-- <div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%"></div>
                        </div> -->
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6 mb-4">
                        <div class="card shadow-sm m-0 h-100 lift">
                            <div class="card-body h-100">
                                <div class="d-flex flex-column h-100">
                                    <div class="flex-1" style="height:80px;">
                                        <img src="/images/product-logo/Scola_Smart_Class_maincolor.svg" height="39" alt="home-image" >
                                    </div>
                                    <div>
                                        <div class="badge badge-light mb-2 font-500"><font-awesome-icon icon="fa-solid fa-thumbs-up" class="mr-2"/>Produk Unggulan</div>
                                        <div class="d-block text-muted-strong">
                                            Solusi pembelajaran digital di sekolah.
                                        </div>
                                        <div class="d-block pt-3">
                                            <router-link to="/product-lms" class="btn btn-link p-0 h-auto text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                        </div>
                                        <div class="d-block pt-4 small text-muted font-italic">Powered by Scola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="card shadow-sm m-0 h-100 lift">
                            <div class="card-body h-100">
                                <div class="d-flex flex-column h-100">
                                    <div class="flex-1" style="height:80px;">
                                        <img src="/images/product-logo/Scola_School_Pro_maincolor.svg" height="36" alt="home-image" >
                                    </div>
                                    <div>
                                        <div class="d-block text-muted-strong">
                                            Kelola data sekolah dengan teratur & terpusat.
                                        </div>
                                        <div class="d-block pt-3">
                                            <router-link to="/product-si" class="btn btn-link p-0 h-auto text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                        </div>
                                        <div class="d-block pt-4 small text-muted font-italic">Powered by Scola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="card shadow-sm m-0 h-100 lift">
                            <div class="card-body h-100">
                                <div class="d-flex flex-column h-100">
                                    <div class="flex-1" style="height:80px;">
                                        <img src="/images/product-logo/Scola_Institution_maincolor.svg" height="36" alt="home-image" >
                                    </div>
                                    <div>
                                        <div class="d-block text-muted-strong">
                                            Lihat performa sekolah Anda dengan spesifik.
                                        </div>
                                        <div class="d-block pt-3">
                                            <router-link to="/product-sil" class="btn btn-link p-0 h-auto text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                        </div>
                                        <div class="d-block pt-4 small text-muted font-italic">Powered by Scola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="card shadow-sm m-0 h-100 lift">
                            <div class="card-body h-100">
                                <div class="d-flex flex-column h-100">
                                    <div class="flex-1" style="height:80px;">
                                        <img src="/images/product-logo/Scola_Content_maincolor.svg" height="36" alt="home-image" >
                                    </div>
                                    <div>
                                        <div class="d-block text-muted-strong">
                                            Integrasi konten untuk dukung pembelajaran digital.
                                        </div>
                                        <!-- <div class="d-block pt-3">
                                            <router-link to="/product-sil" class="btn btn-link p-0 h-auto text-sm text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                        </div> -->
                                        <div class="d-block pt-4 small text-muted font-italic">Powered by Scola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="card shadow-sm m-0 h-100 lift">
                            <div class="card-body h-100">
                                <div class="d-flex flex-column h-100">
                                    <div class="flex-1" style="height:80px;">
                                        <img src="/images/product-logo/Scola_Apps_maincolor.svg" height="36" alt="home-image" >
                                    </div>
                                    <div>
                                        <div class="d-block text-muted-strong">
                                            Temukan aplikasi sesuai standar Scola untuk dukung pembelajaran di sekolah.
                                        </div>
                                        <div class="d-block pt-3">
                                            <router-link to="/product-sil" class="btn btn-link p-0 h-auto text-sm text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                        </div>
                                        <div class="d-block pt-4 small text-muted font-italic">Powered by Scola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- Numbers Data -->
        <div class="section section-lg pb-3">
            <div class="container-fluid container-fluid-gap">
                <div class="row row-sm">
                    <div class="col-md-4 py-2">
                        <div class="card-body py-0">
                            <div class="text-mega font-600">100+</div>
                            <div class="text-md font-500">Sekolah dan lembaga pendidikan pengguna Scola di Indonesia</div>
                        </div>
                    </div>
                    <div class="col-md-4 py-2">
                        <div class="card-body py-0">
                            <div class="text-mega font-600">8000+</div>
                            <div class="text-md font-500">Total guru pengguna Scola di Indonesia</div>
                        </div>
                    </div>
                    <div class="col-md-4 py-2">
                        <div class="card-body py-0">
                            <div class="text-mega font-600">55000+</div>
                            <div class="text-md font-500">Total siswa pengguna Scola di Indonesia</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Program -->
        <!-- <div class="section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="d-block mb-5">
                    <div class="h2 text-mega font-600">Program terbaik kami saat ini.</div>
                </div>
                <div class="row row-sm">
                    <div class="col-md-3 col-6 mb-md-4 mb-5">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/program-sekolah-hebat-banner-1.png');"></div>
                            </div>
                            <div class="card-content-caption mb-3">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Sekolah Hebat</div>
                                <div class="d-block text-sm">Program kolaborasi antara Scola & Pesona Edu untuk menghadirkan ekosistem pendidikan lengkap dengan buku interaktif.</div>
                            </div>
                            <router-link to="/program-sekolah-hebat" class="btn btn-white rounded-pill">Lihat Program</router-link>
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-md-4 mb-5">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/program-sekolah-digital-banner-2.png');"></div>
                            </div>
                            <div class="card-content-caption mb-3">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Sekolah Digital</div>
                                <div class="d-block text-sm">Program dari Scola khusus untuk sekolah-sekolah yang minat bergabung selama program GESS Event.</div>
                            </div>
                            <router-link to="/program-sekolah-digital" class="btn btn-white rounded-pill">Lihat Program</router-link>
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-md-4 mb-5">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/program-sekolah-mitra-banner-3.png');"></div>
                            </div>
                            <div class="card-content-caption mb-3">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Sekolah Mitra</div>
                                <div class="d-block text-sm">Program dari Scola khusus untuk sekolah-sekolah yang minat bergabung selama program GESS Event.</div>
                            </div>
                            <router-link to="/program-sekolah-mitra" class="btn btn-white rounded-pill">Lihat Program</router-link>
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-md-4 mb-5">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/program-akan-datang-banner.png');"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Program Akan Datang</div>
                                <div class="d-block text-sm">Kami selalu memberikan program-program terbaik untuk dukung ekosistem pendidikan di sekolah anda!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Video & Testimoni -->
        <div class="section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="row">
                    <div class="col-md-6 mb-md-0 mb-5">
                        <div class="d-block h-100 pr-lg-4">
                            <div class="card-content-video-cover">
                                <div class="card-content-video-cover-inner" style="background-image:url('/images/homebanner-1.webp');"></div>
                                <div class="card-content-video-cover-caption text-white">
                                    <div class="d-block mb-4">
                                        <div class="text-xxlg d-block font-600 mb-3">Temukan semua solusi disini.</div>
                                        <!-- <div class="d-flex align-items-center">
                                            <div class="user-avatar flex-shrink-0 mr-3"></div>
                                            <div class="font-600">Video Pengenalan Scola</div>
                                        </div> -->
                                    </div>
                                    <button type="button" data-toggle="modal" data-target="#modalVideo" class="btn btn-white rounded-pill" @click="iframe = true">Putar Video<font-awesome-icon icon="fa-solid fa-circle-play" class="ml-2" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-block pl-lg-4">
                            <div class="d-block mb-5">
                                <div class="d-block text-md mb-2"><span class="text-indigo font-600">"Aplikasi ini menjadi harapan saya untuk pemebelajaran secara digital yang terstruktur.</span> Sekolah sangat terbantu sekali untuk memciru para guru ke era teknologi masa depan."</div>
                                <div class="d-flex align-items-center">
                                    <div class="user-avatar flex-shrink-0 mr-3" style="background-image:url('/images/profile-icon-scola.webp');"></div>
                                    <div class="font-600">Felix, S.T., M.Kom., Wakil Kepala Sekolah SMAK 2 BPK</div>
                                </div>
                            </div>
                            <div class="d-block mb-5">
                                <div class="d-block text-md mb-2"><span class="text-orange font-600">"Saya banyak sekali terbantu dalam proses belajar mengajar secara daring.</span> Aplikasi Scola juga memudahkan saya dalam menyampaikan materi pembelajaran."</div>
                                <div class="d-flex align-items-center">
                                    <div class="user-avatar flex-shrink-0 mr-3" style="background-image:url('/images/profile-icon-scola.webp');"></div>
                                    <div class="font-600">Erhernanda, S.Pd, Guru SD Palm Kids Purwokerto</div>
                                </div>
                            </div>
                            <div class="d-block mb-5">
                                <div class="d-block text-md mb-2"><span class="text-teal font-600">"Saya sebagai orang tua merasa senang dan bangga karena anak saya menemukan solusi masalah belajarnya</span> dan akhirnya semangat belajar anak saya semakin tinggi."</div>
                                <div class="d-flex align-items-center">
                                    <div class="user-avatar flex-shrink-0 mr-3" style="background-image:url('/images/profile-icon-scola.webp');"></div>
                                    <div class="font-600">Handita, Orang Tua Zefanya, Kelas 10 D - SMP St. Aloysius Batununggal</div>
                                </div>
                            </div>
                            <!-- <div class="d-block">
                                <a href="#" class="btn btn-white rounded-pill">Lihat Semua Pengguna</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Product -->
        <div class="section-w-bg-block">
            <div class="section-xl">
                <div class="container-fluid container-fluid-gap">
                    <div class="row row-sm align-items-center">
                        <div class="col-md-6 order-md-1 order-2">
                            <div class="d-block pr-lg-5">
								<!-- <div class="d-block mb-4">
									<img src="/images/product-logo/Scola_Smart_Class_maincolor.svg" height="40" alt="home-image" >
								</div> -->
                                <div class="d-flex align-items-center mb-4" style="margin-left:-8px;">
                                    <img src="/images/icon-product-1.webp" width="80" alt="home-image" >
                                    <div class="d-block font-600 text-lg ml-2">SMART CLASS</div>
                                </div>
                                <div class="d-block text-xxlg font-600 mb-4">Solusi pembelajaran digital di sekolah.</div>
                                <p class="d-block text-md text-muted-strong mb-4">
                                    Scola merancang sistem untuk kemudahan sekolah dalam kelola proses pembelajaran, mengintegrasikan, dan menciptakan kolaborasi efektif antara guru, siswa, orang tua, dan sekolah.
                                </p>
                                <div class="d-block">
                                    <router-link to="/product-lms" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                            <div class="d-block pl-lg-5">
                                <img src="/images/lms-img-2.webp" class="w-100" alt="home-image" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-xl">
                <div class="container-fluid container-fluid-gap">
                    <div class="row row-sm align-items-center">
                        <div class="col-md-6 mb-md-0 mb-5">
                            <img src="/images/sil-img-2.webp" class="w-100" alt="home-image" >
                        </div>
                        <div class="col-md-6">
                            <div class="d-block pl-lg-5">
                                <!-- <div class="d-block font-600 text-primary mb-4 text-md">Sistem Informasi Sekolah</div> -->
								<!-- <div class="d-block mb-4">
									<img alt="home-image" src="/images/product-logo/Scola_School_Pro_maincolor.svg" height="37">
								</div> -->
                                <div class="d-flex align-items-center mb-4" style="margin-left:-8px;">
                                    <img alt="home-image" src="/images/icon-product-2.webp" width="80">
                                    <div class="d-block font-600 text-lg ml-2">SCHOOL PRO</div>
                                </div>
                                <div class="d-block text-xxlg font-600 mb-4">Kelola data sekolah dengan teratur & terpusat.</div>
                                <p class="d-block text-md text-muted-strong mb-4">
                                    Solusi untuk digitalisasi proses akademik, kegiatan, dan kelola semua data di sekolah yang dapat di pantau dengan baik dalam mendukung pengambilan keputusan.
                                </p>
                                <div class="d-block">
                                    <router-link to="/product-si" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-xl">
                <div class="container-fluid container-fluid-gap">
                    <div class="row row-sm align-items-center">
                        <div class="col-md-6 order-md-1 order-2">
                            <div class="d-block pr-lg-5">
                                <!-- <div class="d-block font-600 text-primary mb-4 text-md">Sistem Informasi Lembaga</div> -->
								<!-- <div class="d-block mb-4">
									<img src="/images/product-logo/Scola_Institution_maincolor.svg" height="37" alt="home-image" >
								</div> -->
                                <div class="d-flex align-items-center mb-4" style="margin-left:-8px;">
                                    <img src="/images/icon-product-3.webp" width="80" alt="home-image" >
                                    <div class="d-block font-600 text-lg ml-2">INSTITUTION</div>
                                </div>
                                <div class="d-block text-xxlg font-600 mb-4">Lihat performa sekolah Anda dengan spesifik.</div>
                                <p class="d-block text-md text-muted-strong">
                                    Solusi untuk digitalisasi data dan monitoring perkembangan semua sekolah di bawah naungan lembaga.
                                </p>
                                <div class="d-block">
                                    <router-link to="/product-sil" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                             <img src="/images/si-img-2.webp" class="w-100" alt="home-image" >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Scola App -->
        <!-- <div class="section-w-bg-block section-w-bg-block-primary-dark">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="d-block mb-4">
                                <img src="/images/product-logo/Scola_Apps_white.svg" height="37" alt="home-image" >
                            </div>
                            <div class="d-block mb-5">
                                <div class="h2 text-xxlg font-600 text-white">Dukungan aplikasi untuk membantu proses pembelajaran digital di sekolah.</div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-sm">
                        <div class="col-md-3">
                            <router-link to="/app-vibook" class="card bg-white m-0">
                                <div class="d-block p-3">
                                    <img src="/images/vibook-logo.jpg" class="w-100 rounded" alt="home-image" >
                                </div>
                            </router-link>
                            <div class="d-block font-500 text-md mt-3 text-white">
                                Vibook - Perpustakaan Digital
                            </div>
                        </div>
                        <div class="col-md-3">
                            <router-link to="/app-tutooria" class="card bg-white m-0">
                                <div class="d-block p-3">
                                    <img src="/images/tutooria-logo.png" class="w-100 rounded" alt="home-image" >
                                </div>
                            </router-link>
                            <div class="d-block font-500 text-md mt-3 text-white">
                                Tutooria - Platform Belajar Bersama Tutor
                            </div>
                        </div>
                        <div class="col-md-3">
                            <router-link to="/app-virtualin" class="card bg-white m-0">
                                <div class="d-block p-3">
                                    <img src="/images/virtualin-logo.png" class="w-100 rounded" alt="home-image" >
                                </div>
                            </router-link>
                            <div class="d-block font-500 text-md mt-3 text-white">
                                Virtualin - Rancang Pameran Online Sekolah
                            </div>
                        </div>
                        <div class="col-md-3">
                            <router-link to="/app-edtiva" class="card bg-white m-0">
                                <div class="d-block p-3">
                                    <img src="/images/edtiva-logo.png" class="w-100 rounded" alt="home-image" >
                                </div>
                            </router-link>
                            <div class="d-block font-500 text-md mt-3 text-white">
                                Edtiva - Platform Pembelajaran Online
                            </div>
                        </div>
                    </div>
                    <div class="d-block small text-white pt-5">Scola Apps powered by Scola</div>
                </div>
            </div>
        </div> -->
        <!-- Content Integration -->
        <div class="section-w-bg-block section-w-bg-block-primary">
            <div class="section-lg">
                <div class="continer-fluid container-fluid-gap">
                    <div class="row">
                        <div class="col-md-8">
                            <!-- <div class="d-block mb-4">
                                <img src="/images/product-logo/Scola_Content_white.svg" height="37" alt="home-image" >
                            </div> -->
                            <div class="d-flex align-items-center mb-4" style="margin-left:-8px;">
                                <img src="/images/icon-product-4.webp" width="80" alt="home-image" >
                                <div class="d-block font-600 text-lg ml-2 text-white">CONTENT</div>
                            </div>
                            <div class="d-block mb-5">
                                <div class="h2 text-xxlg font-600 text-white">Ratusan konten & materi dapat diakses dimanapun kapanpun.</div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-sm">
                        <div class="col-md-4 mb-4">
                            <div class="card bg-primary-dark-2 h-100 m-0">
                                <div class="card-body p-5 h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-5 text-white">
                                                <div class="d-block font-600 text-lg mb-3">Video Materi</div>
                                                <div class="d-block">Media pembelajaran yang menyajikan audio dan visual untuk membantu siswa dalam memahami suatu materi pembelajaran.</div>
                                            </div>
                                        </div>
                                        <!-- <div class="row row-sm">
                                            <div class="col-6">
                                                <div class="card bg-white m-0">
                                                    <div class="d-block p-2">
                                                        <img src="/images/pesona-edu-logo.jpg" class="w-100" alt="home-image" >
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="d-block">
                                            <img src="images/img-materi-4.webp" class="w-100 rounded-lg shadow-sm" alt="home-image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div class="card bg-primary-dark-2 h-100 m-0">
                                <div class="card-body p-5 h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-5 text-white">
                                                <div class="d-block font-600 text-lg mb-3">Video Animasi</div>
                                                <div class="d-block">Media pembelajaran yang menggabungkan media audio dan media visual untuk menarik perhatian siswa.</div>
                                            </div>
                                        </div>
                                        <!-- <div class="row row-sm">
                                            <div class="col-6">
                                                <div class="card bg-white m-0">
                                                    <div class="d-block p-2">
                                                        <img  src="/images/vibook-logo.jpg" class="w-100" alt="home-image">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                         <div class="d-block">
                                            <img src="images/app-animasi.webp" class="w-100 rounded-lg shadow-sm" alt="home-image" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div class="card bg-primary-dark-2 h-100 m-0">
                                <div class="card-body p-5 h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-5 text-white">
                                                <div class="d-block font-600 text-lg mb-3">Modul Ajar</div>
                                                <div class="d-block">Buku digital lengkap dengan kemudahan akses kapan pun dan di mana pun melalui berbagai perangkat.</div>
                                            </div>
                                        </div>
                                        <!-- <div class="row row-sm">
                                            <div class="col-md-6">
                                                <div class="card bg-white m-0">
                                                    <div class="d-block p-2">
                                                        <img src="/images/vibook-logo.jpg" class="w-100" alt="home-image" >
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                         <div class="d-block">
                                            <img src="images/img-materi-3.webp" class="w-100 rounded-lg shadow-sm" alt="home-image" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div class="card bg-primary-dark-2 h-100 m-0">
                                <div class="card-body p-5 h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-5 text-white">
                                                <div class="d-block font-600 text-lg mb-3">Video Pembahasan</div>
                                                <div class="d-block">Media pembelajaran untuk menyampaikan materi berupa video dengan topik tertentu.</div>
                                            </div>
                                        </div>
                                        <!-- <div class="row row-sm">
                                            <div class="col-md-6">
                                                <div class="card bg-white m-0">
                                                    <div class="d-block p-2">
                                                        <img src="/images/vibook-logo.jpg" class="w-100" alt="home-image" >
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                         <div class="d-block">
                                            <img src="images/app-penjelasan.webp" class="w-100 rounded-lg shadow-sm" alt="home-image" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div class="card bg-primary-dark-2 h-100 m-0">
                                <div class="card-body p-5 h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-5 text-white">
                                                <div class="d-block font-600 text-lg mb-3">Buku Interaktif</div>
                                                <div class="d-block">Buku interaktif dilengkapi animasi dan simulasi interaktif untuk belajar lebih menyenangkan.</div>
                                            </div>
                                        </div>
                                        <!-- <div class="row row-sm">
                                            <div class="col-6">
                                                <div class="card bg-white m-0">
                                                    <div class="d-block p-2">
                                                        <img src="/images/app-bukuinterakatif.webp" class="w-100" alt="home-image" >
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="d-block">
                                            <img src="images/app-bukuinterakatif.webp" class="w-100 rounded-lg shadow-sm" alt="home-image" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="col-md-4 mb-4">
                            <div class="card bg-primary-dark-2 h-100 m-0">
                                <div class="card-body p-5 h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-5 text-white">
                                                <div class="d-block font-600 text-lg mb-3">Buku Digital</div>
                                                <div class="d-block">Buku digital yang menginspirasi siswa untuk membaca dan mendapatkan banyak sumber.</div>
                                            </div>
                                        </div>
                                        <!-- <div class="row row-sm">
                                            <div class="col-6">
                                                <div class="card bg-white m-0">
                                                    <div class="d-block p-2">
                                                        <img src="/images/app-bukudigital.webp" class="w-100" alt="home-image" >
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                         <div class="d-block">
                                            <img src="images/app-bukudigital.webp" class="w-100 rounded-lg shadow-sm" alt="home-image" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-block small text-white pt-3 font-italic">Scola Content powered by Scola</div>
                </div>
            </div>
        </div>
        <!-- School -->
        <div class="d-block mb-5">
            <school-list-components></school-list-components>
        </div>
        <!-- CTA -->
        <cta-components></cta-components>
        <!-- Contact -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>
        <!-- Modal -->
        <div class="modal fade" id="modalVideo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="iframe = false">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <iframe width="100%" v-if="iframe" id="iframe" height="415" src="https://www.youtube.com/embed/mnnUxQnUd1Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import SchoolList from '@/components/SchoolList.vue'
    import Cta from '@/components/Cta.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'

    import lozad from 'lozad';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faCirclePlay, faPlus, faArrowRight, faEnvelope, faUser, faThumbsUp, faArrowsLeftRight} from '@fortawesome/free-solid-svg-icons';

    library.add(faPlus, faCirclePlay, faArrowRight, faEnvelope, faUser, faThumbsUp, faArrowsLeftRight)

    export default {
        name: 'HomeNew',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'school-list-components': SchoolList,
            'cta-components': Cta,
            'help-cta-components': HelpCta,
            'footer-components': Footer,
        },
        data () {
            return {
                iframe : false
            }
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);

        },
        methods: {
        scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }

    }

</script>

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import Home from '../views/Home.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [{
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/home-new',
    name: 'home-new',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/HomeNew.vue')
  },
  {
    path: '/product',
    name: 'product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Product.vue')
  },
  {
    path: '/product-lms',
    name: 'product-lms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/ProductLMS.vue')
  },
  {
    path: '/product-si',
    name: 'product-si',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/ProductSI.vue')
  },
  {
    path: '/product-sil',
    name: 'product-sil',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/ProductSIL.vue')
  },
  {
    path: '/school-solution-lms',
    name: 'school-solution-lms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SchoolSolutionLMS.vue')
  },
  {
    path: '/teacher-solution-lms',
    name: 'teacher-solution-lms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/TeacherSolutionLMS.vue')
  },
  {
    path: '/parent-solution-lms',
    name: 'parent-solution-lms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/ParentSolutionLMS.vue')
  },
  {
    path: '/si-repository',
    name: 'si-repository',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SIRepository.vue')
  },
  {
    path: '/si-ppdb',
    name: 'si-ppdb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SIPPDB.vue')
  },
  {
    path: '/si-sarpras',
    name: 'si-sarpras',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SISarpras.vue')
  },
  {
    path: '/si-pendidik',
    name: 'si-pendidik',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SITendik.vue')
  },
  {
    path: '/si-rapor',
    name: 'si-rapor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SIRapor.vue')
  },
  {
    path: '/si-keuangan',
    name: 'si-keuangan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SIKeuangan.vue')
  },
  {
    path: '/si-portal',
    name: 'si-portal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SIPortalPendidikan.vue')
  },
  {
    path: '/si-kepala-sekolah',
    name: 'si-kepala-sekolah',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SIKepalaSekolah.vue')
  },
  {
    path: '/si-tata-usaha',
    name: 'si-tata-usaha',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SITataUsaha.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/fitur-andalan',
    name: 'fitur-andalan',
    component: () => import( /* webpackChunkName: "about" */ '../views/Feature.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( /* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/konten',
    name: 'konten',
    component: () => import( /* webpackChunkName: "about" */ '../views/Content.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( /* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/virtual-class',
    name: 'virtual-class',
    component: () => import( /* webpackChunkName: "about" */ '../views/VirtualClass.vue')
  },
  {
    path: '/ujian-online',
    name: 'ujian-online',
    component: () => import( /* webpackChunkName: "about" */ '../views/UjianOnline.vue')
  },
  {
    path: '/manajemen-sistem',
    name: 'manajemen-sistem',
    component: () => import( /* webpackChunkName: "about" */ '../views/ManajemenSistem.vue')
  },
  {
    path: '/register-success',
    name: 'register-success',
    component: () => import( /* webpackChunkName: "about" */ '../views/RegisterSuccess.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import( /* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import( /* webpackChunkName: "about" */ '../views/Blog.vue')
  },
  {
    path: '/event',
    name: 'event',
    component: () => import( /* webpackChunkName: "about" */ '../views/Event.vue')
  },
  {
    path: '/blog-read',
    name: 'blog-read',
    component: () => import( /* webpackChunkName: "about" */ '../views/BlogRead.vue')
  },
  {
    path: '/program',
    name: 'program',
    component: () => import( /* webpackChunkName: "about" */ '../views/Program.vue')
  },
  {
    path: '/solusi-scola-guru',
    name: 'solusi-scola-guru',
    component: () => import( /* webpackChunkName: "about" */ '../views/SolusiGuru.vue')
  },
  {
    path: '/solusi-scola-sekolah',
    name: 'solusi-scola-sekolah',
    component: () => import( /* webpackChunkName: "about" */ '../views/SolusiSekolah.vue')
  },
  {
    path: '/solusi-scola-orangtua',
    name: 'solusi-scola-orangtua',
    component: () => import( /* webpackChunkName: "about" */ '../views/SolusiOrangTua.vue')
  },
  {
    path: '/security-disclosure',
    name: 'security-disclosure',
    component: () => import( /* webpackChunkName: "about" */ '../views/SecurityDisclosure.vue')
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import( /* webpackChunkName: "about" */ '../views/TermsOfService.vue')
  },
  {
    path: '/program-sekolah-hebat',
    name: 'program-sekolah-hebat',
    component: () => import( /* webpackChunkName: "about" */ '../views/ProgramSekolahHebat.vue')
  },
  {
    path: '/program-sekolah-digital',
    name: 'program-sekolah-digital',
    component: () => import( /* webpackChunkName: "about" */ '../views/ProgramSekolahDigital.vue')
  },
  {
    path: '/program-sekolah-mitra',
    name: 'program-sekolah-mitra',
    component: () => import( /* webpackChunkName: "about" */ '../views/ProgramSekolahMitra.vue')
  },
  {
    path: '/app-vibook',
    name: 'app-vibook',
    component: () => import( /* webpackChunkName: "about" */ '../views/AppVibook.vue')
  },
  {
    path: '/app-tutooria',
    name: 'app-tutooria',
    component: () => import( /* webpackChunkName: "about" */ '../views/AppTutooria.vue')
  },
  {
    path: '/app-virtualin',
    name: 'app-virtualin',
    component: () => import( /* webpackChunkName: "about" */ '../views/AppVirtualin.vue')
  },
  {
    path: '/app-edtiva',
    name: 'app-edtiva',
    component: () => import( /* webpackChunkName: "about" */ '../views/AppEdtiva.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
            return { selector: to.hash }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
  }})

export default router
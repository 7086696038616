<template>
    <div class="section section-lg pb-5 section-footer">
        <div class="container-fluid container-fluid-gap">
            <div class="row">
                <div class="col-md-5 mb-md-0 mb-5">
                    <div class="d-block mb-md-4 mb-5">
                        <router-link to="/">
                            <img src="https://assets.scola.id/newwebsite/images/Scola_Logo_Lockup_Blue_NEW.svg" alt="Scola Logo" width="140">
                        </router-link>
                    </div>
                    <div class="d-block pl-3 border-left">
                        <div class="d-block text-sm mb-2">
                            Wujudkan Sekolah Digital Anda Bersama Scola, aplikasi ekosistem pendidikan digital dengan solusi terlengkap dan terbaik!
                        </div>
                        <div class="d-block font-500 text-sm mb-3">
                            #ekosistempendidikandigital
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="d-none footer-group">
                        <div class="footer-item--group">
                            <div class="footer-item">
                                <ul class="footer-item--list">
                                    <li class="title">Scola</li>
                                    <li><router-link to="/about">Tentang Kami</router-link></li>
                                    <li><router-link to="/product">Produk Terbaik</router-link></li>
                                    <li><router-link to="/program">Program</router-link></li>
                                    <li><a href="https://artikel.scola.id/" target="_blank">Artikel</a></li>
                                </ul>
                            </div>
                            <div class="footer-item">
                                <ul class="footer-item--list">
                                    <li class="title">Solusi Sekolah</li>
                                    <!-- <li><router-link to="solusi-scola-sekolah">Solusi untuk Sekolah</router-link></li>
                                    <li><router-link to="solusi-scola-guru">Solusi untuk Guru</router-link></li>
                                    <li><router-link to="solusi-scola-orangtua">Solusi untuk Orang Tua</router-link></li> -->
                                    <li class="mb-3">
                                        <div class="d-flex align-items-center">
                                            <img src="/images/icon-product-1.webp" class="flex-shrink-0 mr-2" alt="icon-product-1">
                                            <div class="d-block">
                                                <div class="d-block font-500">Smart Class</div>
                                                <div class="d-block text-sm">Pembelajaran</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mb-3">
                                        <div class="d-flex align-items-center">
                                            <img src="/images/icon-product-2.webp" class="flex-shrink-0 mr-2" alt="icon-product-2">
                                            <div class="d-block">
                                                <div class="d-block font-500">School Pro</div>
                                                <div class="d-block text-sm">Sistem Informasi</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mb-3">
                                        <div class="d-flex align-items-center">
                                            <img src="/images/icon-product-3.webp" class="flex-shrink-0 mr-2" alt="icon-product-3">
                                            <div class="d-block">
                                                <div class="d-block font-500">Institution</div>
                                                <div class="d-block text-sm">SI Yayasan</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mb-3">
                                        <div class="d-flex align-items-center">
                                            <img src="/images/icon-product-4.webp" class="flex-shrink-0 mr-2" alt="icon-product-4">
                                            <div class="d-block">
                                                <div class="d-block font-500">Content</div>
                                                <div class="d-block text-sm">Media Pembelajaran</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-item">
                                <ul class="footer-item--list">
                                    <li class="title">Bantuan</li>
                                    <li><a href="https://api.whatsapp.com/send?phone=628562151145" target="_blank" rel="noopener noreferrer">Pemasaran &
                                            Kemitraan</a></li>
                                    <li><a href="https://api.whatsapp.com/send?phone=6281318667468" target="_blank" rel="noopener noreferrer">Layanan &amp; Dukungan Teknis</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-3 col-6">
                            <ul class="footer-item--list">
                                <li class="title">Scola</li>
                                <li><router-link to="/about">Tentang Kami</router-link></li>
                                <li><router-link to="/product">Produk Terbaik</router-link></li>
                                <li><router-link to="/program">Program</router-link></li>
                                <li><a href="https://artikel.scola.id/" target="_blank">Artikel</a></li>
                            </ul>
                        </div>
                        <div class="col-md-9 col-6">
                            <ul class="footer-item--list">
                                <li class="title">Solusi Sekolah</li>
                            </ul>
                            <div class="row">
                                <div class="col-md-6">
                                    <ul class="footer-item--list">
                                        <li class="mb-3">
                                            <router-link to="/product-lms" class="d-flex align-items-center link-naked">
                                                <img src="/images/icon-product-1.webp" width="46" class="flex-shrink-0 mr-2" alt="icon-product-1">
                                                <div class="d-block">
                                                    <div class="d-block font-500">Smart Class</div>
                                                    <div class="d-block text-sm">Pembelajaran</div>
                                                </div>
                                            </router-link>
                                        </li>
                                        <li class="mb-3">
                                            <router-link to="/product-si" class="d-flex align-items-center link-naked">
                                                <img src="/images/icon-product-2.webp" width="46" class="flex-shrink-0 mr-2" alt="icon-product-2">
                                                <div class="d-block">
                                                    <div class="d-block font-500">School Pro</div>
                                                    <div class="d-block text-sm">Sistem Informasi</div>
                                                </div>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6">
                                    <ul class="footer-item--list">
                                        <li class="mb-3">
                                            <router-link to="/product-sil" class="d-flex align-items-center link-naked">
                                                <img src="/images/icon-product-3.webp" width="46" class="flex-shrink-0 mr-2" alt="icon-product-3">
                                                <div class="d-block">
                                                    <div class="d-block font-500">Institution</div>
                                                    <div class="d-block text-sm">SI Yayasan</div>
                                                </div>
                                            </router-link>
                                        </li>
                                        <li class="mb-3">
                                            <div class="d-flex align-items-center">
                                                <img src="/images/icon-product-4.webp" width="46" class="flex-shrink-0 mr-2" alt="icon-product-4">
                                                <div class="d-block">
                                                    <div class="d-block font-500">Content</div>
                                                    <div class="d-block text-sm">Media Pembelajaran</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr>
                    <div class="d-block text-xs pt-3">
                        <div class="d-block text-muted mb-md-2 mb-4">
                            Copyright © 2010 - 2023 Scola Learning Management System. All Rights Reserved.
                        </div>
                        <div class="d-flex d-flex-mobile">
                            <router-link to="privacy-policy" class="mr-3 text-muted mb-md-0 mb-2">Kebijakan Privasi</router-link>
                            <router-link to="terms-of-service" class="mr-3 text-muted mb-md-0 mb-2">Ketentuan Penggunaan</router-link>
                            <router-link to="security-disclosure" class="mr-3 text-muted mb-md-0 mb-4">Security Disclosure</router-link>
                        </div>
                    </div>
                    <div class="d-block pt-3 text-xs">
                        <span class="withlove" style="margin-left:auto">Made with ❤ from Indonesia</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>
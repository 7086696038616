<template>
    <div id="navMobile" class="main-navigation-mobile main-navigation-mobile--full">
        <div class="container-fluid">
            <!-- <router-link to="/" class="d-block mb-5">
                <img src="https://assets.scola.id/newwebsite/images/Scola_Logo_Lockup_Blue_NEW.svg" alt="Scola Logo" width="112">
            </router-link> -->
            <div class="d-flex align-items-center justify-content-between pb-3 border-bottom mb-4">
                <router-link to="/about" class="text-xxxlg font-500 text-dark">Tentang Kami</router-link>
                <font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-lg"/>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-4">
                <router-link to="/product" class="text-xxxlg font-500 text-dark">Produk</router-link>
                <font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-lg"/>
            </div>
            <div class="row justify-container-center mb-4">
                <div class="col-12 mb-4">
                    <div class="card shadow-sm m-0 h-100 border">
                        <div class="card-body h-100">
                            <div class="d-flex flex-column h-100">
                                <div class="flex-1" style="height:80px;">
                                    <img src="/images/product-logo/Scola_Smart_Class_maincolor.svg" height="39" alt="scola-smart-color">
                                </div>
                                <div>
                                    <div class="badge badge-light mb-2 font-500"><font-awesome-icon icon="fa-solid fa-thumbs-up" class="mr-2"/>Produk Unggulan</div>
                                    <div class="d-block text-muted-strong">
                                        Solusi pembelajaran digital di sekolah.
                                    </div>
                                    <div class="d-block pt-3">
                                        <router-link to="/product-lms" class="btn btn-link p-0 h-auto text-sm text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                    </div>
                                    <div class="d-block pt-4 small text-muted">Powered by Scola</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-4">
                    <div class="card shadow-sm m-0 h-100 border">
                        <div class="card-body h-100">
                            <div class="d-flex flex-column h-100">
                                <div class="flex-1" style="height:80px;">
                                    <img src="/images/product-logo/Scola_School_Pro_maincolor.svg" height="36" alt="scola-school-pro">
                                </div>
                                <div>
                                    <div class="d-block text-muted-strong">
                                        Kelola data sekolah dengan teratur & terpusat.
                                    </div>
                                    <div class="d-block pt-3">
                                        <router-link to="/product-si" class="btn btn-link p-0 h-auto text-sm text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                    </div>
                                    <div class="d-block pt-4 small text-muted">Powered by Scola</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-4">
                    <div class="card shadow-sm m-0 h-100 border">
                        <div class="card-body h-100">
                            <div class="d-flex flex-column h-100">
                                <div class="flex-1" style="height:80px;">
                                    <img src="/images/product-logo/Scola_Institution_maincolor.svg" height="36" alt="scola-institution-maincolor">
                                </div>
                                <div>
                                    <div class="d-block text-muted-strong">
                                        Lihat performa sekolah Anda dengan spesifik.
                                    </div>
                                    <div class="d-block pt-3">
                                        <router-link to="/product-sil" class="btn btn-link p-0 h-auto text-sm text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                    </div>
                                    <div class="d-block pt-4 small text-muted">Powered by Scola</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mb-4">
                    <div class="card shadow-sm m-0 h-100 border">
                        <div class="card-body h-100">
                            <div class="d-flex flex-column h-100">
                                <div class="flex-1" style="height:80px;">
                                    <img src="/images/product-logo/Scola_Content_maincolor.svg" height="36" alt="scola-content-maincolor">
                                </div>
                                <div>
                                    <div class="d-block text-muted-strong">
                                        Integrasi konten untuk dukung pembelajaran digital.
                                    </div>
                                    <div class="d-block pt-4 small text-muted">Powered by Scola</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card shadow-sm m-0 h-100 border">
                        <div class="card-body h-100">
                            <div class="d-flex flex-column h-100">
                                <div class="flex-1" style="height:80px;">
                                    <img src="/images/product-logo/Scola_Apps_maincolor.svg" height="36" alt="scola-apps">
                                </div>
                                <div>
                                    <div class="d-block text-muted-strong">
                                        Temukan aplikasi sesuai standar Scola untuk dukung pembelajaran di sekolah.
                                    </div>
                                    <div class="d-block pt-4 small text-muted">Powered by Scola</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between pb-3 border-bottom mb-4">
                <router-link to="/program" class="text-xxxlg font-500 text-dark">Program</router-link>
                <font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-lg"/>
            </div>
            <!-- <div class="d-flex align-items-center justify-content-between pb-3 border-bottom mb-4">
                <router-link to="/event" class="text-xxxlg font-500 text-dark">Event</router-link>
                <font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-lg"/>
            </div> -->
            <div class="d-flex align-items-center justify-content-between pb-3 border-bottom mb-4">
                <a href="https://artikel.scola.id/" target="_blank" class="text-xxxlg font-500 text-dark">Artikel</a>
                <font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-lg"/>
            </div>
                <div class="d-flex align-items-center justify-content-between pb-3 border-bottom mb-4">
                <router-link to="/contact" class="text-xxxlg font-500 text-dark">Kontak</router-link>
                <font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-lg"/>
            </div>
            <router-link to="/register" class="btn btn-primary btn-lg btn-block rounded-pill">
                <span class="text-lg">Daftar Sekarang</span>
            </router-link>
        </div>
    </div>
</template>
<script>
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faBolt, faArrowRight } from '@fortawesome/free-solid-svg-icons';

    library.add(faBolt, faArrowRight)

    export default {}
</script>